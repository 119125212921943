<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          CSVアップロード
        </CCardHeader>
        <CCardBody>
        <UploadExcelComponent
              :on-success="handleSuccess"
              :header-array="fields"
              :before-upload="beforeUpload"
              :example-file="exampleFile"
              :is-import="isImport"
              :is-disabled="isDisabled"
              style="margin-bottom: 30px"
              @import="handleImport" />
          <div v-if="loadingForm">
            <CProgress class="mt-1" show-value>
                <CProgressBar
                        :value="progress"
                        color="success"
                />
            </CProgress>
            <LoadingCompoment />
          </div>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <CDataTable
              hover
              striped
              :items="tableData"
              :fields="fields"
              :items-per-page-select="{
                label: '表示件数',
                values: [10, 20, 50, 100, 200, 500],
              }"
              :items-per-page="200"
              pagination
            >
              <td slot="id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].id ? errors[index].id : ''"
                    variant='ghost'
                >
                    {{item.id}}
                </CButton>
              </td>
              <td slot="item_code" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_code}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_code ? errors[index].item_code : ''"
                    variant='ghost'
                >
                    {{item.item_code}}
                </CButton>
              </td>
              <td slot="name" v-bind:class="{'bg-danger': !!errors[index] && errors[index].name}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].name ? errors[index].name : ''"
                    variant='ghost'
                >
                    {{item.name}}
                </CButton>
              </td>
              <td slot="size_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].size_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].size_id ? errors[index].size_id : ''"
                    variant='ghost'
                >
                    {{item.size_id}}
                </CButton>
              </td>
              <td slot="color_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].color_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].color_id ? errors[index].color_id : ''"
                    variant='ghost'
                >
                    {{item.color_id}}
                </CButton>
              </td>
              <td slot="cost" v-bind:class="{'bg-danger': !!errors[index] && errors[index].cost}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].cost ? errors[index].cost : ''"
                    variant='ghost'
                >
                    {{item.cost_commas}}
                </CButton>
              </td>
              <td slot="price" v-bind:class="{'bg-danger': !!errors[index] && errors[index].price}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].price ? errors[index].price : ''"
                    variant='ghost'
                >
                    {{item.price_commas}}
                </CButton>
              </td>
              <td slot="retail_price" v-bind:class="{'bg-danger': !!errors[index] && errors[index].retail_price}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].retail_price ? errors[index].retail_price : ''"
                    variant='ghost'
                >
                    {{item.retail_price_commas}}
                </CButton>
              </td>
              <td slot="business_year" v-bind:class="{'bg-danger': !!errors[index] && errors[index].business_year}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].business_year ? errors[index].business_year : ''"
                    variant='ghost'
                >
                    {{item.business_year}}
                </CButton>
              </td>
              <td slot="month" v-bind:class="{'bg-danger': !!errors[index] && errors[index].month}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].month ? errors[index].month : ''"
                    variant='ghost'
                >
                    {{item.month}}
                </CButton>
              </td>
              <td slot="season_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].season_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].season_id ? errors[index].season_id : ''"
                    variant='ghost'
                >
                    {{item.season_id}}
                </CButton>
              </td>
              <td slot="major_category_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].major_category_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].major_category_id ? errors[index].major_category_id : ''"
                    variant='ghost'
                >
                    {{item.major_category_id}}
                </CButton>
              </td>
              <td slot="middle_category_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].middle_category_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].middle_category_id ? errors[index].middle_category_id : ''"
                    variant='ghost'
                >
                    {{item.middle_category_id}}
                </CButton>
              </td>
              <td slot="minor_category_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].minor_category_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].minor_category_id ? errors[index].minor_category_id : ''"
                    variant='ghost'
                >
                    {{item.minor_category_id}}
                </CButton>
              </td>
              <td slot="pp_bs_code" v-bind:class="{'bg-danger': !!errors[index] && errors[index].pp_bs_code}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].pp_bs_code ? errors[index].pp_bs_code : ''"
                    variant='ghost'
                >
                    {{item.pp_bs_code}}
                </CButton>
              </td>
            </CDataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import {numberWithCommas} from "@/utils/utils";
import apiItemMasterOther from '../../api/itemMasterApi';

export default {
  name: 'ImportItemMaster',
  components: { UploadExcelComponent },
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'item_code', label: '品番コード' },
        { key: 'name', label: '品名' },
        { key: 'size_id', label: '製品サイズ名' },
        { key: 'color_id', label: 'カラー名' },
        { key: 'cost', label: '仕入原価' },
        { key: 'price', label: '販売価格'},
        { key: 'retail_price', label: 'プロパー上代' },
        { key: 'business_year', label: '年度' },
        { key: 'month', label: '展開月ID' },
        { key: 'season_id', label: 'シーズンID' },
        { key: 'major_category_id', label: '大分類ID' },
        { key: 'middle_category_id', label: '中分類ID' },
        { key: 'minor_category_id', label: '小分類ID' },
        { key: 'pp_bs_code', label: 'PP/BS区分' },
      ],
      isImport: true,
      exampleFile: {
        path: '/csv/itemMaster.csv',
        name: 'itemMaster.csv',
      },
      loading: false,
      loadingForm: false,
      key: Math.random(),
      tableHeader: [],
      tableData: [],
      errors: {},
      progress: 0,
      isDisabled: true,
    }
  },
  methods: {
    async handleSuccess({results, header}) {
      this.loading = true
      if(header.error_header) {
        let mess = "ヘッダー名が正しくありません";
        Vue.$toast.error(mess)
        this.loading = false
        return;
      }
      
      results.map((r, index) => {
        results[index].cost_commas = numberWithCommas(r.cost)
        results[index].price_commas = numberWithCommas(r.price)
        results[index].retail_price_commas = numberWithCommas(r.retail_price)
      })
      this.tableData = results
      this.tableHeader = header
      apiItemMasterOther.upload(this.tableData).then(response => {
        this.isDisabled = false
        if (response.data.errors.length) {
          const errors = {}
          response.data.errors.map((e) => {
            errors[e.line] = {}
            Object.keys(e.errors).map((error) => {
              errors[e.line][error] = e.errors[error][0]
            })
          })
          this.errors = errors
          this.key = Math.random()
          this.isDisabled = true
        }
      }).catch(error => {
      }).finally(() => {
        this.loading = false
      })
    },
    beforeUpload(file) {
      this.errors = {}
      return true
    },
    handleImport() {
      this.loadingForm = true
      apiItemMasterOther.import(this.tableData, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      }).then(res => {
        const mess = 'インポートに成功しました。';
        Vue.$toast.success(mess)
        setTimeout(this.$router.back(), 2000)
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = {}
          err.response?.data.errors.map((e) => {
            errors[e.line] = {}
            Object.keys(e.errors).map((error) => {
              errors[e.line][error] = e.errors[error][0]
            })
          })
          this.errors = errors
          this.key = Math.random()
          let mess = 'インポートに失敗しました';
          if(err.response.data.error_first){
            mess = err.response.data.error_first;
          }
          Vue.$toast.error(mess)
        }
      }).finally(() => {
        this.progress = 0
        this.loadingForm = false
      })
    },
  }
}
</script>